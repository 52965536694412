@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   overflow: hidden;
  height: 100vh; /* Prevent scrolling vertically */
  width: 100%; /* Prevent scrolling horizontally */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden {
  display: none;
}

.grid-container {
  user-select: none; /* Prevents text selection */
}

/* Additional examples for specific elements */
.grid-cell {
  user-select: none; /* Prevents text selection within grid cells */
}

.grid-container {
  touch-action: none;
}